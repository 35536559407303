/** @jsxImportSource theme-ui */
import { jsx } from "theme-ui";
import React from "react";

/** @type {{search: React.CSSProperties}} */

const NotSupported = () => {
  return (
    <React.Fragment>
      <div
        style={{
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: "60px",
          maxWidth: "800px",
        }}
      >
        <div
          style={{
            textAlign: "center",
            margin: "50px 0px",
          }}
        >
          <h1
            style={{
              color: "#00502F",
              fontSize: 72,
              fontWeight: "bold",
            }}
          >
            Whoops!
          </h1>
          <p>Your web browser is not supported.</p>
        </div>
        <h4
          style={{
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          What can you do?
        </h4>
        <p>
          Update your browser or switch to a different browser for more
          security, speed and the best experience on this site.
          <br />
          Here are some up to date web browsers that better support our site.
        </p>
        <ul
          style={{
            listStyle: "disc inside",
          }}
        >
          <li>
            <a
              href="https://www.google.com/chrome/"
              style={{
                textDecoration: "underline",
                color: "#00502F",
              }}
            >
              Google Chrome
            </a>
          </li>
          <li>
            <a
              href="https://www.mozilla.org/en-US/firefox/new/"
              style={{
                textDecoration: "underline",
                color: "#00502F",
              }}
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a
              href="https://www.microsoft.com/en-us/windows/microsoft-edge"
              style={{
                textDecoration: "underline",
                color: "#00502F",
              }}
            >
              Microsoft Edge
            </a>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default NotSupported;
