/**
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'

import NotSupported from './src/pages/not-supported'

const Providers = ({ children }) => {
  const detectIE = () => {
    const ua = window.navigator.userAgent

    const msie = ua.indexOf('MSIE ')
    if (msie > 0) {
      // IE 10 or older => return version number
      //return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      return true
    }

    const trident = ua.indexOf('Trident/')
    if (trident > 0) {
      // IE 11 => return version number
      //const rv = ua.indexOf('rv:')
      //return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      return true
    }

    // other browser
    return false
  }

  if (detectIE()) {
    return <NotSupported></NotSupported>
  }

  return children
}

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}

export const onClientEntry = () => {
  if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React)
  }
}
